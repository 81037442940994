import {
    mapGetters,
    mapActions
} from 'vuex';
import api from '@api/index';
import endpoints from '@api/repository';
import Helper from '@utils/misc';
import ConfirmationDialog from '@components/dialogs/ConfirmationDialog';
const Mixins = {
    essentials: {
        components: {
            ConfirmationDialog,
        },
        computed: {
            ...mapGetters(['currentInstitution', 'currentUser', 'accessToken', 'isLoading', 'userSubjects']),
            api: () => api,
            endpoints: () => endpoints,
            Helper: () => Helper,
            essentials() {
                return {
                    accessToken: this.accessToken,
                    handleErrorsFunction: this.handleApiError,
                    setLoading: this.setLoading,
                };
            },
        },
        methods: {
            ...mapActions(['showSnackbar', 'setLoading']),
            async handleApiError(err) {
                console.log(err);
                this.setLoading(false);
                if (err.response) {
                    this.showSnackbar({
                        // title: "Something went wrong",
                        title: err.response.data.message,
                        type: "error",
                    });
                } else {
                    this.showSnackbar({
                        title: "Something went wrong",
                        type: "error",
                    });
                }
            },
        },

    },

    pullToRefresh: {
        methods: {
            ...mapActions(['setOnRefresh']),
            async attachPr() {
                if (this.onRefresh) this.setOnRefresh(this.onRefresh);
                else this.setOnRefresh(null);
                // this.pr = await this.Helper.initPullRefresh(this.onRefresh, this.$vnode.key);
            },
        },
        activated() {
            this.attachPr();
        },
        created() {
            this.attachPr();
        },
    },

    handleAppBarTitle: {
        methods: {
            ...mapActions(['setAppBarTitle'])
        },
        activated() {
            this.setAppBarTitle(this.appBarTitle);
        },
        created() {
            if (!this.$route.meta.keepAlive) this.setAppBarTitle(this.appBarTitle);
        },
        beforeRouteLeave(to, from, next) {
            this.setAppBarTitle(null);
            next();
        },
        watch: {
            appBarTitle: function () {
                if (this.appBarTitle) this.setAppBarTitle(this.appBarTitle);
            }
        }
    },

    downloadEssentials: { //requires data: topicName and computed: documentsList
        methods: {
            async downloadDocument(documentFile, fromAws = true) {
                console.log(documentFile.progress);
                if (documentFile.progress && documentFile.progress > 0) return;
                documentFile.featureName = this.featureName;

                await this.api.download(
                    this,
                    this.essentials,
                    documentFile.document,
                    documentFile,
                    "downloads/",
                    fromAws
                );
            },
            async openFile(document) {
                const getFileOpener = () => import("@ionic-native/file-opener");
                const FileOpener = (await getFileOpener()).FileOpener;
                await this.checkIfDocumentsDownloaded();
                try {
                    await FileOpener.open(document.document_uri, document.document_type);
                }catch(err){
                    //debugger; // eslint-disable-line
                    console.log(err);
                    this.showSnackbar({text: 'Unable to open file. You may not have the right application to open this particular file.', type: 'error'});
                }
            },

            async checkIfDocumentsDownloaded() {
                var {
                    Preferences
                } = await import("@capacitor/preferences");
                var downloadedFiles = (await Preferences.get({
                        key: "downloadedFiles"
                    }))
                    .value;
                downloadedFiles = downloadedFiles ? JSON.parse(downloadedFiles) : [];
                // if file already exists in downloads, assign path to that board document file
                // which will then be used for opening the file
                var self = this;
                this.documentsList.map((bd) => {
                    // find if document already downloaded
                    var downloadedFile = downloadedFiles.find(
                        (df) =>
                        df.featureDocumentId == bd.id &&
                        df.fileName == bd.file_name &&
                        df.feature == this.featureName
                    );
                    console.log('Downloaded File:', downloadedFile);
                    if (downloadedFile) {
                        bd.document_path = downloadedFile.path;
                        bd.document_uri = downloadedFile.uri;
                        bd.document_type = downloadedFile.type;
                        self.$set(bd, "progress", 100);
                    }
                });

                // Object.keys(downloadedFiles.downloads).map((fileName) => {
                //   this.post.board_documents.map((bd) => {
                //     if (bd.file_name == fileName)
                //       bd.document_path = downloadedFiles.downloads[fileName].path;
                //     bd.document_type = downloadedFiles.downloads[fileName].type;
                //     self.$set(bd, "progress", 100);
                //   });
                // });
            },

        }
    },

    handleAppBarOptions: {
        methods: {
            ...mapActions(['setAppBarOptions', 'resetAppBarOptions']),
            setAppBar() {
                console.log("setAppBar");
                if (this.appBarOptions)
                    this.setAppBarOptions(this.appBarOptions);
            }
        },
        beforeDestroy() {
            this.resetAppBarOptions();
        },
        beforeRouteLeave(to, from, next) {
            this.resetAppBarOptions();
            next();
        },
        activated() {
            this.setAppBar();
        },
        created() {
            this.setAppBar();
        },
        watch: {
            appBarOptions: 'setAppBar',

        }
    }
}

export default Mixins;