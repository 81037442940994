<template>
  <v-dialog :value="visible" max-width="500" @click:outside="persistent ? {} : $emit('failureCallback')" :persistent="persistent">
    <v-card>
      <v-card-title class="text-left mb-0 font-weight-bold">{{ title }}</v-card-title>
      <v-card-title v-if="subtitle" class="text-left">{{
        subtitle
      }}</v-card-title>

      <v-card-text
        class="text-left"
        :class="deleteDialog ? 'red--text' : 'black--text'"
        v-if="description"
        v-html="description"
        ></v-card-text
      >
      <slot name="custom-dialog-body"></slot>

      <v-card-actions class="pb-2">
        <v-spacer></v-spacer>
        <v-btn
          v-if="failureText"
          :disabled="isLoading"
          color="black darken-1"
          text
          @click="$emit('failureCallback')"
          >{{ failureText }}</v-btn
        >
        <v-btn
          :color="deleteDialog ? 'red darken-1' : 'green darken-1'"
          :disabled="disableSuccessButton || isLoading"
          @click="$emit('successCallback')"
          class="white--text"
          >{{ successText }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ConfirmationDialog",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    successText: {
      type: String,
      default: "Proceed",
    },
    failureText: {
      type: String,
      default: "Cancel",
    },
    disableSuccessButton: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    deleteDialog: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["isLoading"]),
  },
  methods: {},
};
</script>

<style scoped>
.v-card__title {
  word-break: normal !important;
  font-family: "Montserrat", sans-serif !important;
}
.v-card__text {
  word-break: normal !important;
  font-family: "Montserrat", sans-serif !important;
}
</style>
