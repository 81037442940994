import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                // primary: '#005B53', // intract
                // primary: '#F09C4B', // kraf old
                primary: '#795BBA', // kraf 


                error: '#b71c1c',
                background: '#f6f6f6', // Not automatically applied

            },
            
        },
        icons: {
            iconfont: 'mdi',
        }
    }
});
