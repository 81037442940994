import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth';
import base from './modules/base';

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    base,
    auth,
  }
})

export default store;

// Cypress automatically sets window.Cypress by default
if (window.Cypress) {
  window.__store__ = store;
}