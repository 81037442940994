<template>
  <v-snackbar
    v-model="snackbar.visible"
    :color="snackbar.color"
    multi-line
    :timeout="snackbar.timeout"
    bottom
  >
    <v-layout align-center>
      <v-icon class="pr-3" dark large v-if="snackbar.type == 'success'"
        >mdi-checkbox-marked-circle</v-icon
      >
      <v-icon class="pr-3" dark large v-else-if="snackbar.type == 'error'"
        >mdi-alert-circle</v-icon
      >
      <v-icon class="pr-3" dark large v-else-if="snackbar.type == 'warning'"
        >mdi-alert</v-icon
      >
      <v-icon class="pr-3" dark large v-else-if="snackbar.type == 'info'"
        >mdi-information</v-icon
      >
      <v-layout column>
        <div v-if="snackbar.title">
          <strong>{{ snackbar.title }}</strong>
        </div>
        <div v-html="snackbar.text"></div>
      </v-layout>
      <v-btn outlined @click="snackbar.action.action()" v-if="snackbar.action" color="white">{{snackbar.action.text}}</v-btn>
      <v-btn align-right icon @click="snackbar.visible = false">
      <v-icon class="mx-n2">mdi-close</v-icon>
    </v-btn>
    </v-layout>
    
  </v-snackbar>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "IntractSnackbar",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["snackbar"]),
  },
  methods: {
      ...mapActions([''])
  }
};
</script>