import axios from 'axios';
import Helper from '@utils/misc';


const Methods = Object.freeze({
    "GET": 1,
    "POST": 2,
    "DELETE": 3,
    "PUT": 4,
    "PATCH": 5
})

async function sendHttpRequest({
    url,
    method = Methods.GET,
    token,
    body,
    onUploadProgress
}) {
    //hr= handle response
    let hr = (res) => {
        if (res instanceof Error) return he(res);
        if (res.status == 204) return true;
        return res.data;
    }

    //he = handle error
    let he = (err) => {
        return err;
    }

    var response;
    // checks for the HttpMethod and sends request to the server accordingly
    if (method == Methods.POST) {
        response = await axios.post(url, body, {
            headers: {
                Authorization: token ? 'JWT ' + token : null
            },
            onUploadProgress: onUploadProgress
        }).then((res) => hr(res)).catch((err) => he(err))
    } else if (method == Methods.PUT) {
        response = await axios.put(url, body, {
            headers: {
                Authorization: token ? 'JWT ' + token : null
            },
            onUploadProgress: onUploadProgress

        }).then((res) => hr(res)).catch((err) => he(err))
    } else if (method == Methods.DELETE) {
        response = await axios.delete(url, {
            headers: {
                Authorization: token ? 'JWT ' + token : null
            },
        }).then((res) => hr(res)).catch((err) => he(err))
    } else if (method == Methods.PATCH) {
        response = await axios.patch(url, body, {
            headers: {
                Authorization: token ? 'JWT ' + token : null
            },
        }).then((res) => hr(res)).catch((err) => he(err))
    } else {
        response = await axios.get(url, {
            headers: {
                'Authorization': token ? 'JWT ' + token : null,
            }
        }).then((res) => hr(res)).catch((err) => he(err));
    }
    // let response = (method == Methods.POST) ?
    //     await axios.post(url, body, {
    //         headers: {
    //             Authorization: token ? 'JWT ' + token : null
    //         }
    //     }).then((res) => hr(res)).catch((err) => he(err))
    //     : await axios.get(url, {
    //         headers: {
    //             'Authorization': token ? 'JWT ' + token : null,
    //         }
    //     }).then((res) => hr(res)).catch((err) => he(err));

    return response;
}

async function handleApi({
    apiEssentials,
    responseVar,
    url,
    method = Methods.GET,
    body,
    onUploadProgress
}) {
    apiEssentials.setLoading(true);
    // gets test details
    let responseFromServer = await sendHttpRequest({
        url: url,
        token: apiEssentials.accessToken,
        method: method,
        body: body,
        onUploadProgress: onUploadProgress
    });
    if (!Helper.handleErrors(responseFromServer, apiEssentials.handleErrorsFunction)) return responseVar;

    // display
    responseVar = responseFromServer;

    apiEssentials.setLoading(false);
    return responseVar;
}

async function call(apiEssentials, url, method = Methods.GET, body = {}, onUploadProgress = null) {
    var response;
    response = await handleApi({
        apiEssentials: apiEssentials,
        responseVar: response,
        url: url,
        method: method,
        body: body,
        onUploadProgress: onUploadProgress
    });
    if (response != null) return response;
    else return null;
}

async function download(context, apiEssentials, url, downloadEssentials, path = null, fromAws = true) {
    // DYNAMIC IMPORTS
    const getFileDownloadImport = () => import("js-file-download");
    const fileDownload = (await getFileDownloadImport()).default;
    const getDevice = () => import("@capacitor/device");
    const Device = (await getDevice()).Device;
    const getFileSystem = () => import("@capacitor/filesystem");
    const Filesystem = (await getFileSystem()).Filesystem;
    const getBlobWriter = () => import("capacitor-blob-writer");
    const write_blob = (await getBlobWriter()).default;
    // const Encoding = (await getFileSystem()).Encoding;
    const Directory = (await getFileSystem()).Directory;
    const getPreferences = () => import("@capacitor/preferences");
    const Preferences = (await getPreferences()).Preferences;
    let platform = (await Device.getInfo()).platform;
    if (downloadEssentials.progress == undefined) context.$set(downloadEssentials, 'progress', 0);
    // let blob = await fetch(documentFile.document).then((r) => r.blob());
    // gets file from URL as blob
    let downloadUrl = null;
    if (fromAws) {
        downloadUrl = url + "?"+(new Date()).getTime()
    }else{
        downloadUrl = url
    }
    var response = await axios({
        url: downloadUrl,
        method: "GET",
        responseType: "blob", // important
        //<- not needed because aws is open
        ...!fromAws && {headers: { 
            'Authorization': apiEssentials.accessToken ? 'JWT ' + apiEssentials.accessToken : null,
        }},
        onDownloadProgress: (progressEvent) => {
            downloadEssentials.progress = Math.min(99, Math.round((progressEvent.loaded * 100) / progressEvent.total)); // handles progress
        }
    });

    if (!Helper.handleErrors(response, apiEssentials.handleErrorsFunction)) return;

    if (platform == 'web') { // if web, directly download file in system
        downloadEssentials.progress = null;
        fileDownload(response.data, downloadEssentials.file_name);
        return;
    }
    // convert to base64 save downloaded file in file system in ios and android
    var fullPath = path + downloadEssentials.file_name;
    // var reader = new FileReader();
    let downloadedFileUri = null;
    var storedDownload = null;
    write_blob({
        path: fullPath,
        blob: response.data,
        directory: Directory.External,
        recursive: true,
        on_fallback(error) {            
            console.log("ERROR IN DOWNLOADING BLOB",error);
        }
    }).then(function () {
        Filesystem.getUri({
            path: fullPath,
            directory: Directory.External
        }).then(async function ({uri}) {
            downloadedFileUri = uri;
            console.log("File downloaded and saved at uri", downloadedFileUri);
            // save stored file's path in storage for later use
            var downloadedFiles = (await Preferences.get({
                key: "downloadedFiles"
            })).value;
            downloadedFiles = downloadedFiles ? JSON.parse(downloadedFiles) : [];
            storedDownload = {
                fileName: downloadEssentials.file_name,
                uri: downloadedFileUri,
                path: fullPath,
                type: response.data.type,
                feature: downloadEssentials.featureName,
                subject: downloadEssentials.subject,
                featureDocumentId: downloadEssentials.id,
                created: new Date(),
            };
            console.log("storedDownload",storedDownload);
            downloadedFiles.push(storedDownload);

            await Preferences.set({
                key: 'downloadedFiles',
                value: JSON.stringify(downloadedFiles),
            });

            downloadEssentials.progress = 100;
        });
    });
    
    // reader.readAsDataURL(response.data);
    // reader.onloadend = async function () {
    //     var base64data = reader.result;
    //     var writtenFile = await Filesystem.writeFile({
    //         path: fullPath,
    //         data: base64data,
    //         directory: Directory.Documents,
    //         encoding: Encoding.UTF8,
    //         recursive: true, //creates any missing parent directories
    //     });
    // console.log(writtenFile);
    // // save stored file's path in storage for later use
    // var downloadedFiles = (await Storage.get({ key: "downloadedFiles" })).value;
    // downloadedFiles = downloadedFiles ? JSON.parse(downloadedFiles) : [];
    // var storedDownload= {
    //     fileName: downloadEssentials.file_name,
    //     uri: writtenFile.uri,
    //     path: fullPath, 
    //     type: response.data.type,
    //     feature: downloadEssentials.featureName,
    //     subject: downloadEssentials.subject,
    //     featureDocumentId: downloadEssentials.id,
    //     created: new Date(),
    // };
    // downloadedFiles.push(storedDownload);

    // await Storage.set({
    //     key: 'downloadedFiles',
    //     value: JSON.stringify(downloadedFiles),
    // });

    // }
    return storedDownload
}

let ifiHttp = {
    Methods,
    sendHttpRequest,
    handleApi,
    call,
    download
}

export default ifiHttp;