<template>
  <div>
    <div class="splash-background" v-if="!hideSplash">
      <div class="splash-image-wrapper">
        <transition name="bounce">
          <v-avatar v-if="showImage" class="splash-logo" size="200">
            <v-img
              :src="currentInstitution.logo"
            />
          </v-avatar>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "SplashScreen",
  data() {
    return {
      showImage: false,
      hideSplash: false,
    };
  },
  computed: {
    ...mapGetters(["currentInstitution"]),
  },
  created() {
    setTimeout(() => (this.showImage = true), 200);
    setTimeout(() => (this.showImage = false), 1200);
    setTimeout(() => (this.hideSplash = true), 1700);
  },
};
</script>
<style scoped>
.splash-background {
  margin: auto;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: white;
  z-index: 100;
}
.splash-image-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.splash-logo {
  justify-content: center;
  height: auto;
}

.bounce-enter-active {
  /* animation: bounce-in .5s; */
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>