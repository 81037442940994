import endpoints from '@api/repository';
import ifiHttp from '../index';
// import ApiError from '@utils/helper_classes';

// sends cred to server and gets back refresh and access token in return
export default {
    async createToken(creds) {
        return ifiHttp.sendHttpRequest({ url: endpoints.obtainJWT, method: ifiHttp.Methods.POST, body: creds });
    },
    // refreshes the token by sending the refresh token and receive the refreshed access token in response
    async renewAccessToken(refreshToken) {
        return ifiHttp.sendHttpRequest({ url: endpoints.refreshJWT, method: ifiHttp.Methods.POST, body: { "refresh": refreshToken } });
    },
    //gets self details from the server
    async getMyDetails(token) {
        return ifiHttp.sendHttpRequest({ url: endpoints.getMyDetails, method: ifiHttp.Methods.GET, token: token });
    },
    //gets user's institution details from the server
    async getInstitutionDetails(token, id) {
        return ifiHttp.sendHttpRequest({ url: endpoints.getInstitutionDetails + id.toString() + '/', method: ifiHttp.Methods.GET, token: token });
    },
    //gets room model by sending sections
    async getRoomFromSections(token, sections) {
        return ifiHttp.sendHttpRequest({ url: endpoints.getRoomFromSections + `${sections.firstSection}/${sections.secondSection}/${sections.thirdSection}/`, method: ifiHttp.Methods.GET, token: token});
    }

}