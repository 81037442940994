<template>
  <div>
    <v-bottom-navigation v-if="shouldShow" v-model="value" grow shift app>
      <v-btn
        class="non-focused"
        v-for="item in items"
        :key="item.value"
        :value="item.value"
        @click="selectItem(item)"
        color="white"
      >
        <span>{{ item.title }}</span>
        <v-icon color="primary" v-if="value == item.value">{{
          item.selectedIcon
        }}</v-icon>
        <v-icon v-else>{{ item.icon }}</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BottomBar",
  data() {
    return {
      value: "home",
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    shouldShow() {
      return this.items.find((i) => i.route == this.$route.name) != null;
    },
    items() {
      var self = this;
      return [
        ...!this.currentUser.is_driver ? [{
          title: "Messages",
          value: "messages",
          route: "Messages",
          icon: "mdi-view-dashboard-outline",
          selectedIcon: "mdi-view-dashboard",
        }] : [],
        {
          title: "Home",
          value: "home",
          route: "Home",
          icon: "mdi-home-outline",
          selectedIcon: "mdi-home",
        },
        ...(self.currentUser.is_admin
          ? [
              {
                title: "Admin",
                value: "admin",
                route: "AdminPanel",
                icon: "mdi-account-star-outline",
                selectedIcon: "mdi-account-star",
              },
            ]
          : []),
        {
          title: "Profile",
          value: "profile",
          route: "Profile",
          icon: "mdi-account-circle-outline",
          selectedIcon: "mdi-account-circle",
        },
      ];
    },
  },

  methods: {
    selectItem(item) {
      this.$router.push({ name: item.route });
    },
  },
  watch: {
    $route() {
      if (this.$route.name) {
        var item = this.items.find((i) => i.route == this.$route.name);
        this.value = item ? item.value : "home";
      }
    },
  },
};
</script>